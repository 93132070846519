<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { SearchbarProps } from "./Searchbar.props"
import { customPaths } from "~/utils/constants"
import { addSearchTermIntoCookie } from "~/utils/cookies/searchCookies"

const props = defineProps<SearchbarProps>()

const { triggerFocus, modelValue, forceQuery } = toRefs(props)
const emit =
  defineEmits<{
    (e: "onFocusIn"): void
    (e: "onFocusOut"): void
    (e: "onSearch"): void
    (e: "update:modelValue", value: string): void
  }>()

const inputSearch = ref()
const searchbar = ref()
const lastQueries = useStatefulCookie("lastQueries", {
  maxAge: 2592000,
  default: () => []
})

const sharedSearchStatus = useState("SEARCH_STATE", () => modelValue.value)
const algoliaSearchbarQueryState = useState("searchbar-query-state")

const isFocusedPreMount = ref(false)

onBeforeMount(() => {
  if (
    document.activeElement?.name &&
    props.name === document.activeElement?.name
  ) {
    isFocusedPreMount.value = true
    searchValue.value = document.activeElement?.value
  }
})

onMounted(() => {
  if (
    isFocusedPreMount.value ||
    (document.activeElement?.name &&
      props.name === document.activeElement?.name)
  ) {
    algoliaSearchbarQueryState.value = searchValue.value
    emit("onFocusIn")
  }
  if (props.forceQuery) {
    searchValue.value = props.forceQuery
  }
  isFocusedPreMount.value = false
})

watch(modelValue, (value) => {
  if (props.doNotOpenPopup) return

  sharedSearchStatus.value = value
})

// autofocusing when triggering focus
watch(
  triggerFocus,
  (val) => {
    nextTick(() => {
      if (val) {
        const searchbarInput = searchbar.value?.querySelector("input")
        if (searchbarInput) {
          searchbarInput.focus()
        }
      }
    })
  },
  { immediate: true }
)

const isValid = computed(() => {
  if (inputSearch.value) {
    const node = inputSearch.value.node
    if (node.context.state.dirty && node.context.state.rules)
      return node.context.state.complete
  }
  return true
})

const isFocused = ref(false)

const handleFocusIn = () => {
  isFocused.value = true
  sharedSearchStatus.value = props.modelValue ?? ""
  emit("onFocusIn")
}

const handleFocusOut = () => {
  isFocused.value = false
  emit("onFocusOut")
}

const searchValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    if (!value) value = ""
    emit("update:modelValue", value)
  }
})

// handle everything about placeholder typing like
const currentPlaceholder = ref("")
const WRITING_TIME = 120
const CLEARING_TIME = 40
const interval = ref(WRITING_TIME)
let isWriting = ref(true)

let charToShow = ref(0)
const { pause, resume } = useIntervalFn(() => {
  const placeholder = props.placeholder
  if (placeholder) {
    currentPlaceholder.value = placeholder.slice(0, charToShow.value)

    if (charToShow.value === placeholder.length) {
      isWriting.value = false
      interval.value = CLEARING_TIME
      // pause if placeholder is all written
      stopWriting(500)
    } else if (charToShow.value === 0) {
      isWriting.value = true
      interval.value = WRITING_TIME
    }

    // pause if last char is a comma
    if (isWriting.value && currentPlaceholder.value.slice(-1) === ",") {
      stopWriting(500)
    }
    isWriting.value ? charToShow.value++ : charToShow.value--
  }
}, interval)

const stopWriting = (interval: MaybeComputedRef<number>) => {
  pause()
  useTimeoutFn(() => {
    resume()
  }, interval)
}

const restartWriting = () => {
  pause()
  charToShow.value = 0
  isWriting.value = true
  currentPlaceholder.value = ""
  resume()
}

watch(searchValue, () => {
  if (!!searchValue.value) restartWriting()

  ga4EventSearch()
})
/**
 * GA4 Search event
 */
const { event, sendEvent } = useGAEvent(`track_search`, "custom_event")
/**
 * @description GA4 Search event Debounce
 */
const { isPending, start, stop } = useTimeoutFn(() => {
  if (searchValue.value) {
    sendEvent({ clearEcommerce: false })
  }
}, 1500)
/**
 * @description GA4 Search event Handle
 */
const ga4EventSearch = () => {
  if (isPending) stop()

  event.value = {
    event: "custom_event",
    action: "search",
    search_term: searchValue.value
  }

  start()
}

const cleanValue = () => {
  searchValue.value = ""
  algoliaSearchbarQueryState.value = ""
}

watch(forceQuery, (newForceQuery) => {
  searchValue.value = newForceQuery
})

const { switchState } = useUIState("focus-search")

// This may be used to also disable the search button to make it clear that the query isn't valid
function isSearchInvalid(input: string | undefined): boolean {
  if (!input || input.trim().length === 0) return true

  const specialCharRegex = /^[^a-zA-Z0-9]+$/
  if (specialCharRegex.test(input)) return true

  return false
}

const handleSearchPopup = () => {
  emit("onSearch")

  if (props.doNotOpenPopup) return

  lastQueries.value = addSearchTermIntoCookie(
    lastQueries.value,
    searchValue.value
  )

  isSearchInvalid(searchValue.value)
    ? switchState(true)
    : navigateTo(`${customPaths.search}?query=${searchValue.value}`)
}

const placeholderText = computed(() =>
  props.disabled || !props.isPlaceholderAnimated
    ? props.placeholder
    : currentPlaceholder
)
</script>

<template>
  <div class="search-searchbar w-full" ref="searchbar">
    
<FormKitLazyProvider config-file="true">
<FormKit
      ref="inputSearch"
      :id="name"
      :name="name"
      type="text"
      v-model="searchValue"
      :placeholder="placeholderText"
      :disabled="disabled"
      :classes="{
        outer: 'relative',
        wrapper: 'relative bg-inherit',
        label: 'sr-only',
        input: {
          'py-[12px] pl-4 w-full input-text__textfield bg-white border border-tiffany-main rounded-[100px] placeholder:!text-black-80 placeholder:select-none text-black-main pr-12 md:pr-[100px] ': true,
          'error-border__textfield': !isValid,
          'text-black-20 placeholder:!text-black-20': disabled
        }
      }"
      @focusin="handleFocusIn"
      @focusout="handleFocusOut"
      @keyup.enter="handleSearchPopup"
    >
      <template #suffixIcon>
        <button
          v-if="searchValue && !forceSearchButton"
          @click="cleanValue"
          :disabled="disabled"
          class="absolute right-3 top-1/2 -translate-y-1/2"
        >
          <UtilsIcon
            name="CloseCircleFull.svg"
            :preload="preloadIcons ? true : undefined"
            :fetch-priority="preloadIcons ? 'high' : undefined"
            color="black-40"
            class="h-6 w-6"
          />
        </button>
        <button
          v-else
          :disabled="disabled"
          @click="handleSearchPopup"
          class="
            absolute
            right-[3px]
            top-1/2
            flex
            -translate-y-1/2
            items-center
            justify-center
            rounded-full
            bg-yellow-main
          "
          :class="
            showTextIcon
              ? 'h-[38px] w-[38px] gap-1 md:h-max md:w-max md:py-[9px] md:pl-3 md:pr-2'
              : 'p-[7px]'
          "
        >
          <p v-if="showTextIcon" class="pig-medium hidden md:block">
            {{ $t("searchbar.search") }}
          </p>
          <UtilsIcon
            name="Search.svg"
            class="h-6 w-6 flex-shrink-0"
            :preload="preloadIcons ? true : undefined"
            :fetch-priority="preloadIcons ? 'high' : undefined"
            :class="{ 'md:h-5 md:w-5': showTextIcon }"
            v-if="!isFocusedPreMount"
          />
          <UtilsLoader v-if="isFocusedPreMount" dimensions="small-medium" />
        </button>
      </template>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>
